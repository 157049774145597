import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import Input from './componentsFinance/common/Input';
import Button from './componentsFinance/common/Button';
import Select from './componentsFinance/common/Select';
import { financeTypes } from '../utils/position';
import { number } from '../utils/validators';
import { domain } from '../utils/request';

export default function FinanceForm({ onSubmit, onCancel, loading, initialValues, setMonthType }) {
	const options = Object.entries(financeTypes).map(([value, name]) => ({ id: value, name }));
	const monthOptions = [
		{ id: 'manthly', name: 'Ойлик' },
		{ id: 'zero', name: 'Ноллик ' },
	];

	const [second, setSecond] = useState(null);
	const [first, setFirst] = useState(null);

	async function onSuccess(data) {
		const newData = new FormData();
		if (second) newData.append('second', second, second.name);
		if (first) newData.append('first', first, first.name);

		newData.append('name', data.name);
		newData.append('stir', data.stir);
		newData.append('directorName', data.directorName);
		newData.append('directorStir', data.directorStir);
		newData.append('bank', data.bank);
		newData.append('accountNumber', data.accountNumber);
		newData.append('phoneNumber', data.phoneNumber);
		newData.append('clientPhoneNumber', data.clientPhoneNumber);
		newData.append('mfo', data.mfo);
		newData.append('inspector', data.inspector);
		newData.append('login', data.login);
		newData.append('taxDate', data.taxDate);
		newData.append('contractSum', data.contractSum);

		if (data.type) newData.append('type', data.type);
		newData.append('selectDate', data.selectDate);
		onSubmit(newData);
	}

	return (
		<Formik
			initialValues={{
				name: '',
				stir: '',
				directorName: '',
				directorStir: '',
				bank: '',
				accountNumber: '',
				phoneNumber: '',
				clientPhoneNumber: '',
				mfo: '',
				inspector: '',
				login: '',
				type: '',
				selectDate: '',
				taxDate: '',
				contractSum: '',
				...initialValues,
				first: initialValues && !isEmpty(initialValues.first) ? initialValues.first : '',
				second: initialValues && isEmpty(initialValues.second) ? initialValues.second : '',
			}}
			onSubmit={onSuccess}
		>
			{({ setFieldValue }) => {
				return (
					<Form>
						<div className='columns'>
							<div className='column is-6'>
								<Input name='name' label='Корхона номи' disabled={loading} placeholder='Корхона номи' />
							</div>
							<div className='column is-6'>
								<Input type={number} name='stir' label='Корхона СТИР' disabled={loading} placeholder='Корхона СТИР' />
							</div>
						</div>
						<div className='columns'>
							<div className='column'>
								<Input name='directorName' label='Корхона рахбари' disabled={loading} placeholder='Корхона рахбари' />
							</div>
							<div className='column'>
								<Input type={number} name='directorStir' label='Корхона рахбари СТИР' disabled={loading} placeholder='Корхона рахбари СТИР' />
							</div>
						</div>
						<div className='columns'>
							<div className='column'>
								<Input name='bank' label='Банк' disabled={loading} placeholder='Банк' />
							</div>
							<div className='column'>
								<Input type={number} name='accountNumber' label='Х/р' disabled={loading} placeholder='Х/р' />
							</div>
						</div>
						<div className='columns'>
							<div className='column'>
								<Input name='mfo' label='МФО' disabled={loading} placeholder='МФО' />
							</div>
							<div className='column'>
								<Input name='inspector' label='Солик инспектори' disabled={loading} placeholder='Солик инспектори' />
							</div>
						</div>
						<div className='columns'>
							<div className='column'>
								<Input type='number' name='phoneNumber' label='Хизмат кўрсатувчи банк оператори телефон' disabled={loading} placeholder='Введите номер телефон' />
							</div>
							<div className='column'>
								<Input type='number' name='clientPhoneNumber' label='Корхона телефони' disabled={loading} placeholder='Введите номер телефон' />
							</div>
						</div>
						<div className='columns'>
							<div className='column'>
								<Input name='login' label='Интернет банк логин' disabled={loading} placeholder='Интернет банк логин' />
							</div>
							<div className='column'>
								<Select name='type' options={options} label='Солиқ тури' loading={loading} empty />
							</div>
						</div>
						<div className='columns'>
							<div className='column'>
								<Input type='date' className='level-left' name='taxDate' label='ҚҚСга ўтган ой' disabled={loading} placeholder='ҚҚСга ўтган ой' />
							</div>
							<div className='column'>
								<Input type='number' className='level-right' name='contractSum' label='Сумма контракта' disabled={loading} placeholder='0' />
							</div>
						</div>
						<div className='columns'>
							<div className='column mt-1'>
								<Input type='date' className='level-right' name='selectDate' label='Корхона қўшилаётган ой йили' />
							</div>
							<div className='column' style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '0' }}>
								<label htmlFor='typeMonth'>Ойлик тури</label>
								<select
									onChange={e => setMonthType(e.target.value)}
									defaultValue='manthly'
									name='typeMonth'
									id='typeMonth'
									style={{
										marginTop: '0.25rem',
										width: '100%',
										border: '1px solid #D1D5DB',
										borderRadius: '0.375rem',
										boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
										padding: '.7rem',
										outline: 'none',
										transition: 'border-color 0.2s, box-shadow 0.2s',
									}}
								>
									{monthOptions.map(option => (
										<option key={option.id} value={option.id} disabled={loading}>
											{option.name}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className='columns'>
							<div className='column'>
								<p className='level-left'>Корхонанинг ЭРИ калити</p>

								<input
									onChange={e => {
										setFieldValue('imageFile', e.target.value);
										setFirst(e.target.files[0]);
									}}
									disabled={loading}
									name='second'
									type='file'
								/>
								{initialValues ? (domain + initialValues.first).split('/').pop() : 'Файл юкланмаган'}
							</div>

							<div className='column'>
								Раҳбарнинг ЭРИ калити
								<input
									onChange={e => {
										setFieldValue('imageFile', e.target.value);
										setSecond(e.target.files[0]);
									}}
									disabled={loading}
									name='second'
									type='file'
								/>
								{initialValues ? (domain + initialValues.second).split('/').pop() : 'Файл юкланмаган'}
							</div>
						</div>
						<Button loading={loading} text='Сохранить' type='submit' icon='ion-md-checkmark' className='is-success' /> &nbsp;
						<Button onClick={onCancel} icon='ion-md-close' text='Отмена' className='is-danger' />
					</Form>
				);
			}}
		</Formik>
	);
}
