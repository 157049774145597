import React from 'react';
import cn from 'classnames';
import { Field } from 'formik';
import Select from 'react-select';
import { css, StyleSheet } from 'aphrodite';

import ValidationErrorMessage from './ValidationErrorMessage';

export default function MultiSelect({ name, className, options, label, validate, help, optional, onChange, ...attributes }) {
	return (
		<div className='field'>
			<div className='control'>
				{label ? (
					<label htmlFor={name}>
						{label} &nbsp;
						{optional ? <span className='form-hint'>не обязательно</span> : null}
						{help ? <p className={cn(css(styles.help), 'help has-text-grey-light')}>{help}</p> : null}
					</label>
				) : null}
				<Field name={name} validate={validate}>
					{({ form, field }) => (
						<Select
							isMulti
							className={className}
							onBlur={form.setFieldTouched}
							value={field.value}
							options={options}
							{...attributes}
							onChange={values => {
								if (typeof onChange === 'function') {
									onChange(values);
									return;
								}
								form.setFieldValue(name, values);
							}}
						/>
					)}
				</Field>

				<ValidationErrorMessage name={name} />
			</div>
		</div>
	);
}

const styles = StyleSheet.create({ help: { marginTop: '-2px' } });
