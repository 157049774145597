import cn from 'classnames'
import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'

export default function ZeroTabs() {
    const { path } = useRouteMatch()

    return (
        <div className="tabs">
            <ul>
                <li className={cn({ 'is-active': path.startsWith('/zero/rotation') })}>
                    <NavLink to="/zero/rotation">Айланма</NavLink>
                </li>
                <li className={cn({ 'is-active': path.startsWith('/zero/income-tax') })}>
                    <NavLink to="/zero/income-tax">ЖШДС</NavLink>
                </li>
                <li className={cn({ 'is-active': path.startsWith('/zero/qqc-monthly') })}>
                    <NavLink to="/zero/qqc-monthly">ҚҚС Ойлик</NavLink>
                </li>

                <li className={cn({ 'is-active': path.startsWith('/zero/income-quarterly') })}>
                    <NavLink to="/zero/income-quarterly">Фойда солиғи</NavLink>
                </li>
                <li className={cn({ 'is-active': path.startsWith('/zero/result') })}>
                    <NavLink to="/zero/result">Молиявий Натижа</NavLink>
                </li>
                <li className={cn({ 'is-active': path.startsWith('/zero/balance') })}>
                    <NavLink to="/zero/balance">Молиявий Баланс</NavLink>
                </li>
            </ul>
        </div>
    )
}
