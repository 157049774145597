import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import * as queryString from 'query-string';
import { StyleSheet } from 'aphrodite';
import Button from '../components/common/Button';
import FinanceCreate from '../components/FinaceCreate';
import { useModal } from '../hooks/modal';
import { useQueryParams } from '../hooks/queryString';
import { useLoad, useSortTableData } from '../hooks/request';
import { FINANCE_LIST, MANTHLY_FINANCE_LIST } from '../urls';
import FinanceItem from '../components/FinanceItem';
import TransactionSearch from '../components/TransactionSearch';
import Table from '../components/common/Table';
import Layout from '../components/Layout';

export default function Finance({ history }) {
	const params = useQueryParams();
	const [isVisible, setIsVisible] = useState(false);
	const [isModalOpen, setModalOpen] = useState(false);
	const [formData, setFormData] = useState({
		includeName: false,
		includeStir: false,
		includeDirectorName: false,
		includeDirectorStir: false,
		includeBank: false,
		includeAccount_number: false,
		mfo: false,
		inspector: false,
		phone_number: false,
		login: false,
		type: false,
		tax_date: false,
		contract_sum: false,
		includeMonthly: true,
		includeZero: false,
	});

	const handleInputChange = e => {
		const { name, value, type, checked } = e.target;
		setFormData(prevData => ({ ...prevData, [name]: type === 'checkbox' ? checked : value }));
	};

	const handleClick = () => {
		setIsVisible(!isVisible);
	};

	const handleExport = () => {
		const headers = [];
		const data = [];

		if (formData.includeZero) {
			const monthlyData = finance_manthly.response ? finance_manthly.response.results : [];
			monthlyData.forEach(row => {
				const rowData = [];
				if (formData.includeName) {
					headers.includes('Корхона номи') || headers.push('Корхона номи');
					rowData.push(row.name);
				}
				if (formData.includeStir) {
					headers.includes('Юридик стир') || headers.push('Юридик стир');
					rowData.push(row.stir);
				}
				if (formData.includeDirectorName) {
					headers.includes('Корхона рахбари') || headers.push('Корхона рахбари');
					rowData.push(row.directorName);
				}
				if (formData.includeDirectorStir) {
					headers.includes('Жисмоний стир') || headers.push('Жисмоний стир');
					rowData.push(row.directorStir);
				}
				if (formData.includeBank) {
					headers.includes('Банк') || headers.push('Банк');
					rowData.push(row.bank);
				}
				if (formData.includeAccount_number) {
					headers.includes('Х/р') || headers.push('Х/р');
					rowData.push(row.accountNumber);
				}
				if (formData.mfo) {
					headers.includes('МФО') || headers.push('МФО');
					rowData.push(row.mfo);
				}
				if (formData.inspector) {
					headers.includes('Солик инспектори') || headers.push('Солик инспектори');
					rowData.push(row.inspector);
				}
				if (formData.phone_number) {
					headers.includes('Корхона рахбари телефони') || headers.push('Корхона рахбари телефони');
					rowData.push(row.phoneNumber);
				}
				if (formData.login) {
					headers.includes('Интернет банк логин') || headers.push('Интернет банк логин');
					rowData.push(row.login);
				}
				if (formData.type) {
					headers.includes('Солиқ тури') || headers.push('Солиқ тури');
					rowData.push(row.type === 'qqc_monthly' ? 'Айланма' : 'ҚҚC-ойлик');
				}
				if (formData.tax_date) {
					headers.includes('ҚҚСга ўтган ой') || headers.push('ҚҚСга ўтган ой');
					rowData.push(row.taxDate);
				}
				if (formData.contract_sum) {
					headers.includes('Сумма контракта') || headers.push('Сумма контракта');
					rowData.push(row.contractSum);
				}
				data.push(rowData);
			});
		}

		if (formData.includeMonthly) {
			const zeroData = finance.response ? finance.response.results : [];
			zeroData.forEach(row => {
				const rowData = [];
				if (formData.includeName) {
					headers.includes('Корхона номи') || headers.push('Корхона номи');
					rowData.push(row.name);
				}
				if (formData.includeStir) {
					headers.includes('Юридик стир') || headers.push('Юридик стир');
					rowData.push(row.stir);
				}
				if (formData.includeDirectorName) {
					headers.includes('Корхона рахбари') || headers.push('Корхона рахбари');
					rowData.push(row.directorName);
				}
				if (formData.includeDirectorStir) {
					headers.includes('Жисмоний стир') || headers.push('Жисмоний стир');
					rowData.push(row.directorStir);
				}
				if (formData.includeBank) {
					headers.includes('Банк') || headers.push('Банк');
					rowData.push(row.bank);
				}
				if (formData.includeAccount_number) {
					headers.includes('Х/р') || headers.push('Х/р');
					rowData.push(row.accountNumber);
				}
				if (formData.mfo) {
					headers.includes('МФО') || headers.push('МФО');
					rowData.push(row.mfo);
				}
				if (formData.inspector) {
					headers.includes('Солик инспектори') || headers.push('Солик инспектори');
					rowData.push(row.inspector);
				}
				if (formData.phone_number) {
					headers.includes('Корхона рахбари телефони') || headers.push('Корхона рахбари телефони');
					rowData.push(row.phoneNumber);
				}
				if (formData.login) {
					headers.includes('Интернет банк логин') || headers.push('Интернет банк логин');
					rowData.push(row.login);
				}
				if (formData.type) {
					headers.includes('Солиқ тури') || headers.push('Солиқ тури');
					rowData.push(row.type === 'qqc_monthly' ? 'Айланма' : 'ҚҚC-ойлик');
				}
				if (formData.tax_date) {
					headers.includes('ҚҚСга ўтган ой') || headers.push('ҚҚСга ўтган ой');
					rowData.push(row.tax_date);
				}
				if (formData.contract_sum) {
					headers.includes('Сумма контракта') || headers.push('Сумма контракта');
					rowData.push(row.contract_sum);
				}
				data.push(rowData);
			});
		}

		const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
		const maxLengths = headers.map((header, colIndex) => {
			return Math.max(header.length, ...data.map(row => (row[colIndex] ? row[colIndex].toString().length : 0)));
		});
		worksheet['!cols'] = maxLengths.map(length => ({ width: length + 10 }));
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
		const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
		const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
		saveAs(blob, 'export.xlsx');
		setModalOpen(false);
	};

	const finance = useLoad({ url: FINANCE_LIST, params }, [params]);
	const { items, requestSort, sortConfig } = useSortTableData(finance.response ? finance.response.results : []);

	const getClassNamesFor = name => {
		if (!sortConfig) return;
		return sortConfig.key === name ? sortConfig.direction : undefined;
	};

	const finance_manthly = useLoad({ url: MANTHLY_FINANCE_LIST, params }, [params]);
	const { items: items_manthly, requestSort: requestSort_manthly, sortConfig: sortConfig_manthly } = useSortTableData(finance_manthly.response ? finance_manthly.response.results : []);
	const allItems = [...items, ...items_manthly];
	// const getClassNamesFor_manthly = name => {
	// 	if (!sortConfig_manthly) return;
	// 	return sortConfig_manthly.key === name ? sortConfig_manthly.direction : undefined;
	// };

	const [showModal, hideModal] = useModal(
		<FinanceCreate
			onSuccess={() => {
				hideModal();
				finance.request();
			}}
			onCancel={() => hideModal()}
		/>,
		styles.modal
	);

	function onSearch(query) {
		const search = !isEmpty(query) ? query : undefined;
		history.push(`?${queryString.stringify({ search })}`);
	}

	return (
		<Layout>
			<div style={{ position: 'sticky', top: 0, left: 0, width: '100%', zIndex: 10, backgroundColor: 'white', margin: '0 auto', padding: '0px', display: 'flex', justifyContent: 'space-between' }}>
				<div className='column mt-3' style={{ padding: '5px', maxWidth: '700px' }}>
					<TransactionSearch onSearch={onSearch} />
				</div>

				<div className='mt-3 is-flex' style={{ padding: '5px' }}>
					<div className='is-pulled-right is-success is-outlined'>
						<Button icon='ion-md-download' text='Excel' className='is-pulled-right is-success is-outlined mr-3' onClick={() => setModalOpen(true)} />

						{isModalOpen && (
							<div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
								<div style={{ backgroundColor: 'white', padding: '24px', borderRadius: '12px', boxShadow: '0 10px 25px rgba(0, 0, 0, 0.1)', maxWidth: '400px', width: '100%', boxSizing: 'border-box' }}>
									<h2 style={{ fontSize: '20px', fontWeight: '600', marginBottom: '16px' }}>Excel экспорт учун майдонлар ва маълумотларни танланг</h2>

									<div style={{ marginBottom: '12px' }}>
										<label style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
											<input type='checkbox' name='includeName' checked={formData.includeName} onChange={handleInputChange} style={{ marginRight: '8px' }} /> Корхона номи
										</label>
										<label style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
											<input type='checkbox' name='includeStir' checked={formData.includeStir} onChange={handleInputChange} style={{ marginRight: '8px' }} /> Юридик стир
										</label>
										<label style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
											<input type='checkbox' name='includeDirectorName' checked={formData.includeDirectorName} onChange={handleInputChange} style={{ marginRight: '8px' }} /> Корхона рахбари
										</label>
										<label style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
											<input type='checkbox' name='includeDirectorStir' checked={formData.includeDirectorStir} onChange={handleInputChange} style={{ marginRight: '8px' }} /> Жисмоний стир
										</label>
										<label style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
											<input type='checkbox' name='includeBank' checked={formData.includeBank} onChange={handleInputChange} style={{ marginRight: '8px' }} /> Банк
										</label>
										<label style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
											<input type='checkbox' name='includeAccount_number' checked={formData.includeAccount_number} onChange={handleInputChange} style={{ marginRight: '8px' }} /> Х/р
										</label>
										<label style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
											<input type='checkbox' name='mfo' checked={formData.mfo} onChange={handleInputChange} style={{ marginRight: '8px' }} /> МФО
										</label>
										<label style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
											<input type='checkbox' name='phone_number' checked={formData.phone_number} onChange={handleInputChange} style={{ marginRight: '8px' }} /> Корхона рахбари телефони
										</label>
										<label style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
											<input type='checkbox' name='type' checked={formData.type} onChange={handleInputChange} style={{ marginRight: '8px' }} /> Солиқ тури
										</label>
										<label style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
											<input type='checkbox' name='tax_date' checked={formData.tax_date} onChange={handleInputChange} style={{ marginRight: '8px' }} /> ҚҚСга ўтган ой
										</label>
										<label style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
											<input type='checkbox' name='contract_sum' checked={formData.contract_sum} onChange={handleInputChange} style={{ marginRight: '8px' }} /> Сумма контракта
										</label>
									</div>

									<h2 className='is-size-4 is-capitalized'>маълумотлар</h2>

									<div style={{ marginBottom: '16px' }}>
										<label style={{ display: 'flex', alignItems: 'center' }}>
											<input type='checkbox' name='includeMonthly' checked={formData.includeMonthly} onChange={handleInputChange} style={{ marginRight: '8px' }} /> Ойлик маълумотлар
										</label>
										<label style={{ display: 'flex', alignItems: 'center' }}>
											<input type='checkbox' name='includeZero' checked={formData.includeZero} onChange={handleInputChange} style={{ marginRight: '8px' }} /> Ноллик маълумотлар
										</label>
									</div>

									<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
										<button onClick={() => setModalOpen(false)} style={{ marginRight: '8px', padding: '8px 16px', backgroundColor: '#e0e0e0', borderRadius: '4px', border: 'none', cursor: 'pointer' }}>
											Отмена
										</button>
										<button onClick={handleExport} style={{ padding: '8px 16px', backgroundColor: '#007bff', color: 'white', borderRadius: '4px', border: 'none', cursor: 'pointer' }}>
											Экспорт
										</button>
									</div>
								</div>
							</div>
						)}
					</div>
					{/* <Button
						icon='ion-md-search'
						text='Корхона номи'
						className={cn(getClassNamesFor('name'), getClassNamesFor_manthly('name'), 'is-pulled-right is-success is-outlined mr-3')}
						onClick={() => {
							requestSort('name');
							requestSort_manthly('name');
						}}
					/> */}
					{/* <Button
						icon='ion-md-search'
						text='Корхона стири'
						onClick={() => {
							requestSort('stir');
							requestSort_manthly('stir');
						}}
						className={cn(getClassNamesFor('stir'), getClassNamesFor_manthly('stir'), 'is-pulled-right is-success is-outlined mr-3')}
					/>
					<Button
						icon='ion-md-search'
						text='Рахбари стири'
						onClick={() => {
							requestSort('directorStir');
							requestSort_manthly('directorStir');
						}}
						className={cn(getClassNamesFor('directorStir'), getClassNamesFor_manthly('directorStir'), 'is-pulled-right is-success is-outlined mr-3')}
					/> */}
					{/* <Button
						icon='ion-md-search'
						text='Сумма'
						onClick={() => {
							requestSort('contractSum');
							requestSort_manthly('contractSum');
						}}
						className={cn(getClassNamesFor('contractSum'), getClassNamesFor_manthly('contractSum'), 'is-pulled-right is-success is-outlined mr-3')}
					/> */}
					<Button icon='ion-md-add' onClick={showModal} text='Янги мижоз қўшиш' className='is-pulled-right is-success is-outlined' />
				</div>
			</div>

			<div style={{ margin: '15px 0 20px 0', display: 'flex', alignItems: 'center', gap: '30px' }}>
				<span className='is-size-4'>Мижозлар сони: {allItems?.length && allItems?.length}</span>
				<span className='is-size-4' style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={handleClick}>
					Сумма контракта: {isVisible ? `${allItems?.reduce((a, b) => a + b.contractSum, 0).toLocaleString('ru-RU')} сум` : '*********'}
				</span>
			</div>

			<div style={{ overflow: 'scroll' }}>
				<div style={{ overflow: 'scroll' }}>
					<Table
						loading={finance.loading}
						items={allItems}
						columns={{ id: '№', name: 'Корхона номи', stir: 'Корхона стири', directorName: 'Корхона рахбари', directorStir: 'Рахбари стири', first: 'ЭРИ(Корхона)', second: 'ЭРИ(Раҳбар)', assds: '', asds: '' }}
						renderItem={(item, index) => <FinanceItem page={1} key={item.id} index={index} onUpdate={finance.request} onDelete={finance.request} item={item} />}
					/>

					{/* <br />

					<Table
						loading={finance_manthly.loading}
						items={items_manthly}
						columns={{ id: '№', name: 'Корхона номи', stir: 'Корхона стири', directorName: 'Корхона рахбари', directorStir: 'Рахбари стири', first: 'ЭРИ(Корхона)', second: 'ЭРИ(Раҳбар)', assds: '', asds: '' }}
						renderItem={(item, index) => <ManthlyFinanceItem key={item.id} index={index} page={1} onUpdate={finance_manthly.request} onDelete={finance_manthly.request} item={item} />}
					/> */}
				</div>
			</div>
		</Layout>
	);
}

const styles = StyleSheet.create({ modal: { width: 800 } });
