import cn from 'classnames';
import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';

export default function ManthlyTabs() {
	const { path } = useRouteMatch();

	return (
		<div className='tabs'>
			<ul>
				<li className={cn({ 'is-active': path.startsWith('/manthly/rotation') })}>
					<NavLink to='/manthly/rotation'>Айланма</NavLink>
				</li>
				<li className={cn({ 'is-active': path.startsWith('/manthly/income-tax') })}>
					<NavLink to='/manthly/income-tax'>ЖШДС</NavLink>
				</li>
				{/* <li className={cn({ 'is-active': path.startsWith('/manthly/clients') })}>
                    <NavLink to="/manthly/clients">Мижозлар</NavLink>
                </li> */}
				<li className={cn({ 'is-active': path.startsWith('/manthly/qqc-monthly') })}>
					<NavLink to='/manthly/qqc-monthly'>ҚҚС Ойлик</NavLink>
				</li>
				<li className={cn({ 'is-active': path.startsWith('/manthly/income-quarterly') })}>
					<NavLink to='/manthly/income-quarterly'>Фойда солиғи</NavLink>
				</li>

				<li className={cn({ 'is-active': path.startsWith('/manthly/result') })}>
					<NavLink to='/manthly/result'>Молиявий Натижа</NavLink>
				</li>
				<li className={cn({ 'is-active': path.startsWith('/manthly/balance') })}>
					<NavLink to='/manthly/balance'>Молиявий Баланс</NavLink>
				</li>
			</ul>
		</div>
	);
}
