import React, { useState } from 'react';
import { usePostRequest } from '../hooks/request';
import { FINANCE_LIST, MANTHLY_FINANCE_LIST } from '../urls';
import FinanceForm from './FinanceForm';
import ServerError from './common/ServerError';

export default function FinanceCreate({ onCancel, onSuccess }) {
	const [monthType, setMonthType] = useState('manthly');
	const financeCreate = usePostRequest({ url: monthType === 'manthly' ? FINANCE_LIST : MANTHLY_FINANCE_LIST });
	const [serverError, setServerError] = useState();

	async function onSubmit(data) {
		const { success, error } = await financeCreate.request({ data });
		if (error) {
			setServerError(error);
		}
		if (success) {
			onSuccess();
		}
	}

	return (
		<div>
			<b>Добавить</b>
			<br />
			<br />
			<ServerError error={serverError} />
			<FinanceForm onSubmit={onSubmit} onCancel={onCancel} loading={financeCreate.loading} setMonthType={setMonthType} />
		</div>
	);
}
