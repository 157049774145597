import React from 'react';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile';
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet';
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn';
import { Form, Formik } from 'formik';
import { useLoad, useSortTableData } from '../hooks/request';
import { FINANCE_LIST, INCOME_QUARTERLY_DETAIL, INCOME_QUARTERLY_ITEM_LIST, USERS_LIST } from '../urls';
import Table from '../components/common/Table';
import IncomeQuarterlyItemItem from '../components/IncomeQuarterlyItemItem';
import Button from '../components/common/Button';
import Layout from '../components/Layout';
import UserSelect from '../components/componentsFinance/common/UserSelect';
import { getMonth } from '../utils/date';
import { checkPermission as cp } from '../utils/auth';

export default function UploadIncomeQuarterly() {
	const { incomeQuarterlyId } = useParams();
	const quarterly = useLoad({
		url: FINANCE_LIST,
		params: { type: 'qqc_monthly' },
	});

	const reportDetail = useLoad({ url: INCOME_QUARTERLY_DETAIL.replace('{id}', incomeQuarterlyId) });
	const reportItems = useLoad({ url: INCOME_QUARTERLY_ITEM_LIST.replace('{incomeQuarterlyId}', incomeQuarterlyId) });
	const { items, requestSort, sortConfig } = useSortTableData(quarterly.response ? quarterly.response.results : []);
	const getClassNamesFor = name => {
		if (!sortConfig) {
			return;
		}
		return sortConfig.key === name ? sortConfig.direction : undefined;
	};
	const customStyles = {
		fontFamily: 'Arial',
		fontSize: '12px',
		backgroundColor: '#ccc',
		border: '1px solid black',
	};

	return (
		<Layout>
			<div>
				<div className='level'>
					<div className='level-left'>
						<span className='title is-capitalized'>
							{reportDetail.response ? reportDetail.response.quarter : ''} - {reportDetail.response ? getMonth(reportDetail.response.date) : ''}
						</span>
					</div>
					<div className='level-right'>
						<div className='is-pulled-right is-success is-outlined mr-3'>
							<ExcelFile element={<Button icon='ion-md-download' text='Excel' className='is-pulled-right is-success is-outlined mr-3' />} filename={reportDetail.response ? getMonth(reportDetail.response.date) : ''}>
								<ExcelSheet style={customStyles} name='Excel' data={reportItems.response ? reportItems.response.results : []}>
									<ExcelColumn label='Корхона номи' value='name' />
									<ExcelColumn label='Юридик стир' value='stir' />
									<ExcelColumn label='Жисмоний стир' value='directorStir' />
								</ExcelSheet>
							</ExcelFile>
						</div>
						<Button icon='ion-md-search' text='Корхона номи' className={cn(getClassNamesFor('name'), 'is-pulled-right is-success is-outlined mr-3')} onClick={() => requestSort('name')} />
						<Button icon='ion-md-search' text='Юридик стир' onClick={() => requestSort('stir')} className={cn(getClassNamesFor('stir'), 'is-pulled-right is-success is-outlined mr-3')} />
						<Button icon='ion-md-search' text='Жисмоний стир' onClick={() => requestSort('directorStir')} className={cn(getClassNamesFor('directorStir'), 'is-pulled-right is-success is-outlined')} />
					</div>
				</div>

				<Table
					loading={quarterly.loading}
					items={items}
					columns={{ id: '№', name: 'Мижоз корхона номи', stir: 'Юридик стир', directorStir: 'Жисмоний стир', uploadRotation: 'Фойда солиғи', commentRotation: 'Изоҳ', tax: 'Солиқ', ad: '' }}
					renderItem={(item, index) => (
						<IncomeQuarterlyItemItem index={index} key={item.id} onUpdate={quarterly.request} onDelete={quarterly.request} request={reportItems.request} reportItems={reportItems.response ? reportItems.response.results : []} incomeQuarterlyId={incomeQuarterlyId} finance={item} />
					)}
				/>
			</div>
		</Layout>
	);
}
